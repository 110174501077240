import * as Constants from "../constants"
import RouteHome from "../routes/RouteHome.svelte"
import RouteAdvisors from "../routes/RouteAdvisors.svelte"
import RouteSubscribers from "../routes/RouteSubscribers.svelte"
import RouteClubdsDeals from "../routes/RouteClubsDeals.svelte"
import RouteLogin from "../routes/RouteLogin.svelte"
import RouteAdvisorsDetails from "../routes/RouteAdvisorsDetails.svelte"
import RouteSubscribersDetails from "../routes/RouteSubscribersDetails.svelte"
import RouteClubsDealsDetails from "../routes/RouteClubsDealsDetails.svelte"
export const routes = [
    {
        path: Constants.HOME_PATH,
        component: RouteHome
    },
    {
        path: Constants.ADVISORS_PATH,
        component: RouteAdvisors
    },
    {
        path: Constants.ADVISORS_DETAILS_PATH,
        component: RouteAdvisorsDetails
    },
    {
        path: Constants.SUBSCRIBERS_PATH,
        component: RouteSubscribers
    },
    {
        path: Constants.SUBSCRIBERS_DETAILS_PATH,
        component: RouteSubscribersDetails
    },
    {
        path: Constants.CD_PATH,
        component: RouteClubdsDeals
    },
    {
        path: Constants.CD_DETAILS_PATH,
        component: RouteClubsDealsDetails
    }
]