<script>
  export let material_icon = undefined;

  /**@props {String: "primary"} color - color of badge
   * * primary [default]
   * * secondary
   * * white
   * * white-blue
   * * white-danger
   * * danger
   * * warning
   * * progress
   * * disabled
   * * blue
   * * gradient
   **/
  export let color = "primary";
  const OPTIONS_LIST = [
    "primary",
    "secondary",
    "white",
    "white-blue",
    "white-danger",
    "danger",
    "warning",
    "progress",
    "disabled",
    "blue",
    "gradient",
  ];
  if (!OPTIONS_LIST.includes(color)) {
    throw new Error(
      `value of 'color' ('${color}') in <AppBadge/> is not valable`
    );
  }
  export let date = undefined;
</script>

<span class="c-appbadge--{color}" class:date>
  {#if date}
    <b>{date}</b>
  {/if}
  {#if typeof material_icon == "string"}
    <i class="material-icons">{material_icon}</i>
  {/if}
  <slot />
</span>

<style>
  span {
    padding: 8px 12px;
    border-radius: 2px;
    font-family: "Lato";
    font-weight: 700;
    font-size: 12px;
    display: inline-block;
    &.date {
      padding: 16px;
      font-size: 14px;
      display: block;
      border-radius: 4px;
      font-weight: 900;
      b {
        font-size: 11px;
        color: $color_primary;
        font-weight: 400;
        display: block;
        margin-bottom: 8px;
      }
    }
    i {
      font-size: 13px;
      margin-right: 4px;
      height: 100%;
      vertical-align: middle;
      margin-bottom: 2px;
      line-height: 15px;
    }
    &.c-appbadge {
      &--primary {
        color: #675aff;
        background: #e6e3ff;
        &.date b {
          color: #675aff;
        }
      }
      &--progress {
        color: $progress;
        background: #ffe9d4;
      }
      &--white {
        background: $white;
        box-shadow: $primary-shadow;
        &-blue {
          color: #2714f5;
          background: $white;
          box-shadow: $primary-shadow;
        }
        &-danger {
          color: $danger;
          background: $white;
          box-shadow: $primary-shadow;
        }
      }
      &--danger {
        color: $danger;
        background: rgb(255, 193, 193);
        &.date b {
          color: red;
        }
      }
      &--gradient {
        color: $white;
        background: linear-gradient(
          142.64deg,
          #002ad0 -19.57%,
          #1100ff 31.34%,
          #8f01ff 110.35%
        );
      }
      &--disabled {
        color: #828282;
        background: #e0e0e0;
        padding: 8px 14px;
        &.date {
          padding: 16px;
          font-size: 14px;
          display: block;
          border-radius: 4px;
          font-weight: 900;
        }
      }
    }
  }
</style>
