export const HOME_PATH = "/"
export const LOGIN_PATH = "/login"
export const ADVISORS_PATH = "/managers"
export const ADVISORS_DETAILS_PATH = "/managers/:id"
export const SUBSCRIBERS_PATH = "/participants"
export const SUBSCRIBERS_DETAILS_PATH = "/participants/:id"
export const CD_PATH = "/dossiers"
export const CD_DETAILS_PATH = "/dossiers/:id"
export const EMAIL_RULE = /[\w\._-]+@[\w_-]+[.][a-z]+/;

export const LOGO_PATH = "/assets/logo/logo-fr.svg"

export const REGION_LIST = [
    {
        value: "auvergneRhoneAlpes",
        label: "Auvergne-Rhône-Alpes"
    },
    {
        value: "bourgogneFrancheComte",
        label: "Bourgogne-Franche-Comté"
    },
    {
        value: "bretagne",
        label: "Bretagne"
    },
    {
        value: "centreValDeLoire",
        label: "Centre-Val de Loire"
    },
    {
        value: "grandEst",
        label: "Grand Est"
    },
    {
        value: "hautsDeFrance",
        label: "Hauts-de-France"
    },
    {
        value: "ileDeFrance",
        label: "Île-de-France"
    },
    {
        value: "normandie",
        label: "Normandie"
    },
    {
        value: "nouvelleAquitaine",
        label: "Nouvelle-Aquitaine"
    },
    {
        value: "occitanie",
        label: "Occitanie"
    },
    {
        value: "paysDeLaLoire",
        label: "Pays de la Loire"
    },
    {
        value: "provenceAlpesCoteDAzur",
        label: "Provence-Alpes-Côte d'Azur"
    },
    {
        value: "corse",
        label: "Corse"
    },
    {
        value: "guadeloupe",
        label: "Guadeloupe"
    },
    {
        value: "guyane",
        label: "Guyane"
    },
    {
        value: "martinique",
        label: "Martinique"
    },
    {
        value: "laReunion",
        label: "La Réunion"
    },
    {
        value: "mayotte",
        label: "Mayotte"
    }
]

export const COUNTRY_LIST = [
    {
        value: "austria",
        label: "Autriche"
    },
    {
        value: "belgium",
        label: "Belgique"
    },
    {
        value: "bulgaria",
        label: "Bulgarie"
    },
    {
        value: "croatia",
        label: "Croatie"
    },
    {
        value: "canada",
        label: "Canada"
    },
    {
        value: "iceland",
        label: "Islande"
    },
    {
        value: "cyprus",
        label: "Chypre"
    },
    {
        value: "czechia",
        label: "République tchèque"
    },
    {
        value: "denmark",
        label: "Danemark"
    },
    {
        value: "estonia",
        label: "Estonie"
    },
    { 
        value: "unitedStates",
        label: "États-Unis"
    },
    {
        value: "finland",
        label: "Finlande"
    },
    {
        value: "France",
        label: "France"
    },
    {
        value: "germany",
        label: "Allemagne"
    },
    {
        value: "greece",
        label: "Grèce"
    },
    {
        value: "hungary",
        label: "Hongrie"
    },
    {
        value: "ireland",
        label: "Irlande"
    },
    {
        value: "italy",
        label: "Italie"
    },
    {
        value: "latvia",
        label: "Lettonie"
    },
    {
        value: "lithuania",
        label: "Lithuanie"
    },
    {
        value: "luxembourg",
        label: "Luxembourg"
    },
    {
        value: "malta",
        label: "Malte"
    },
    {
        value: "monaco",
        label: "Monaco"
    },
    {
        value: "norway",
        label: "Norvège"
    },
    {
        value: "netherlands",
        label: "Pays-Bas"
    },
    {
        value: "poland",
        label: "Pologne"
    },
    {
        value: "portugal",
        label: "Portugal"
    },
    {
        value: "romania",
        label: "Roumanie"
    },
    {
        value: "slovakia",
        label: "Slovaquie"
    },
    {
        value: "slovenia",
        label: "Slovénie"
    },
    {
        value: "spain",
        label: "Espagne"
    },
    {
        value: "sweden",
        label: "Suède"
    },
    {
        value: "unitedKingdom",
        label: "Royaume-Uni"
    },
    {
        value: "switzerland",
        label: "Suisse"
    },
]

export const INSTRUMENTS_LISTS = [
    {
        value: "equity",
        label: "Equity"
    },
    {
        value: "bond",
        label: "Obligation sèche"
    },
    {
        value: "convertibleBond",
        label: "Obligation convertible"
    },
    {
        value: "bondEquityMix",
        label: "Mix equity / obligataire"
    },
]
export const TYPOLOGIES_LISTS = [
    {
        value: "capitalIncrease",
        label: "Augmentation de capital"
    },
    {
        value: "securitiesTransfer",
        label: "Cession de titres"
    },
    {
        value: "bondIssue",
        label: "Emission obligataire"
    },
    {
        value: "other",
        label: "Autre"
    },
]
export const PERIODICITIES_LIST = [
    {
        value: "monthly",
        label: "Mensuelle"
    },
    {
        value: "quarterly",
        label: "Trimestrielle"
    },
    {
        value: "twiceYearly",
        label: "Semestrielle"
    },
    {
        value: "yearly",
        label: "Annuelle"
    },
];

export const STATE_LIST = [
    {
        value: "validated",
        label: "Compte validé"
    },
    {
        value: "confirmed",
        label: "Compte à valider"
    },
    {
        value: "blacklisted",
        label: "Blackliste"
    },
    {
        value: "created",
        label: "Compte en création"
    }
]