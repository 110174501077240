<script>
  import AppImage from "../Img/AppImg.svelte";
  import AppText from "../Text/AppText.svelte";
  import AppBadge from "../Badge/AppBadge.svelte";
  import { fade } from "svelte/transition";
  export let src;
  export let loading = true;
  export let name;
  
  export let state = {
    text: "En cours de souscription",
    color: "primary",
  };
</script>

<main>
  {#if !loading}
    <aside transition:fade>
      <section>
        {#if src}
          <AppImage {src} />
        {/if}
        <div>
          <AppText size="large" weight="900">{name || "Inconnu"}</AppText>
          <aside>
            <AppBadge color={state.color}>{state.text}</AppBadge>
          </aside>
        </div>
      </section>
      <slot />
    </aside>
  {:else}
    <figure transition:fade>
      <img src="/assets/tail-spin.svg" />
    </figure>
  {/if}
</main>

<style>
  main {
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    section {
      display: flex;
      border-bottom: 1px solid $gray;
      padding-bottom: 16px;
      margin-bottom: 24px;
      div {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    figure {
      position: fixed;
      top: 0;
      left: 0;
      @include respond-to('md'){
        left: 224px;
      }
      right: 0;
      bottom: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 64px;
      }
    }
  }
</style>
