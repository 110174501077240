<script>
  import { Cell, Row } from "@smui/data-table";
  import HeaderText from "../../components/Text/HeaderText.svelte";
  import AppTable from "../../components/Table/AppTable.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  let header = ["Nom", "Prénom", "Email", "Société"];
  export let data;
</script>

<Spacing y="8" />
<HeaderText>Gestionnaire</HeaderText>
<AppTable {header}>
  <Row>
    <Cell>{data.clubDealAdvisor.lastName}</Cell>
    <Cell>{data.clubDealAdvisor.firstName}</Cell>
    <Cell>{data.clubDealAdvisor.email}</Cell>
    <Cell>{data.clubDealAdvisor.company}</Cell>
  </Row>
</AppTable>

<Spacing y="16" />
<HeaderText>Co-gestionnaires</HeaderText>
<AppTable {header} empty={data.clubDealCoAdvisors.length == 0}>
  {#each data.clubDealCoAdvisors as coAdvisor}
    <Row>
      <Cell>{coAdvisor.lastName}</Cell>
      <Cell>{coAdvisor.firstName}</Cell>
      <Cell>{coAdvisor.email}</Cell>
      <Cell>{coAdvisor.company}</Cell>
    </Row>
  {/each}
</AppTable>
