<script>
  import API from "../../utils/api";
  import { onMount, onDestroy } from "svelte";

  import Dialog, { Content } from "@smui/dialog";
  import Input from "../../components/Form/Input.svelte";
  import { EMAIL_RULE } from "../../constants";
  import { Cell, Row } from "@smui/data-table";
  import AppButton from "../../components/Button/AppButton.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import AppTable from "../../components/Table/AppTable.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  let header = ["Email", "Retirer"];
  let dialog;
  let email = [""];
  let loading = {
    add: false,
    delete: false,
  };
  export let data;
  onMount(() => {
    data.clubDealInvestorsEmails = addLoadings(data.clubDealInvestorsEmails);
  });
  onDestroy(() => {
    data.clubDealInvestorsEmails = emailOnly(data.clubDealInvestorsEmails);
  });

  function addLoadings(array) {
    return array.map((email) => {
      return { email, loading: false };
    });
  }
  function emailOnly(array) {
    return array.map((el) => el.email);
  }
  const UpdateAdivsor = async (loading_type, clubDealInvestorsEmails, c) => {
    if (loading[loading_type] === true) return;
    loading[loading_type] = true;
    let not_ok = false;
    try {
      await API.put("admin/clubdeal", {
        id: data.id,
        clubDealInvestorsEmails: emailOnly(clubDealInvestorsEmails),
      });
      data.clubDealInvestorsEmails = [...clubDealInvestorsEmails];
    } catch (e) {
      console.error(e);
      not_ok = true;
    } finally {
      if (c) {
        c(not_ok);
      }
      loading[loading_type] = false;
    }
  };
  const addInvest = async () => {
    dialog.close();
    const clubDealInvestorsEmails = [
      ...data.clubDealInvestorsEmails,
      ...addLoadings(email),
    ];
    await UpdateAdivsor("add", clubDealInvestorsEmails, () => {
      email[0] = "";
    });
  };
  const deleteInvest = async (i) => {
    data.clubDealInvestorsEmails[i]["loading"] = true;
    const clubDealInvestorsEmails = data.clubDealInvestorsEmails.filter(
      (_, y) => y !== i
    );
    await UpdateAdivsor("delete", clubDealInvestorsEmails, (not_ok) => {
      if (not_ok) data.clubDealInvestorsEmails[i].loading = false;
    });
  };

  $: empty = data.clubDealInvestorsEmails.length === 0;
  $: disabledDialog = !EMAIL_RULE.test(email[0]);
</script>

<AppButton on:click={() => dialog.open()} loading={loading.add}
  >Ajouter un participant</AppButton
>
<Dialog bind:this={dialog}>
  <Content>
    <AppText color="blue" size="large" weight="900">
      Inviter un participant
    </AppText>
    <Spacing y="8" />
    <Input bind:value={email[0]} />
    <AppButton
      on:click={addInvest}
      loading={loading.add}
      disabled={disabledDialog}
    >
      Ajouter le participant
    </AppButton>
  </Content>
</Dialog>
<Spacing y="8" />
<AppTable {header} {empty}>
  {#each data.clubDealInvestorsEmails as { email, loading }, i}
    <Row>
      <Cell>
        <AppText>{email}</AppText>
      </Cell>
      <Cell>
        <AppButton {loading} on:click={() => deleteInvest(i)} icon color="alert"
          >close</AppButton
        >
      </Cell>
    </Row>
  {/each}
</AppTable>

<!-- <style>
  div {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
</style> -->
