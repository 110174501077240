<script>
  import Spacing from "../components/Spacing/Spacing.svelte";
  import AppText from "../components/Text/AppText.svelte";
  import HeaderText from "../components/Text/HeaderText.svelte";
  import AppInput from "../components/Form/Input.svelte";
  import Select from "svelte-select";
  import AppDot from "../components/Dot/AppDot.svelte";
  import UserCard from "../components/Card/UserCard.svelte";
  import LayoutContent from "../components/Layout/LayoutContent.svelte";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import State from "../utils/functions/State";
  import API from "../utils/api";

  let loading = true;
  let data = [];
  let filteredData = [];
  let filter = "";
  let allStates = [
    {
      value: "unconfirmed",
      label: "Non confirmé",
    },
    {
      value: "confirmed",
      label: "Confirmé",
    },
    {
      value: "validated",
      label: "Validé",
    },
    {
      value: "blacklisted",
      label: "Suspendu",
    },
  ];
  let state = null;
  $: {
    filteredData = data;
    if (filter.trim().length) {
      let filters = filter
        .split(" ")
        .filter((e) => e.length)
        .map((e) => e.toLowerCase());
      filteredData = data.filter((a) => {
        const str =
          `${a.firstName} ${a.lastName} ${a.email} ${a.company}`.toLowerCase();
        return filters.every((f) => str.indexOf(f) > -1);
      });
    }
    if (state) {
      if (state == "unconfirmed") {
        filteredData = filteredData.filter((a) => !a.confirmed && !a.validated);
      } else if (state == "confirmed") {
        filteredData = filteredData.filter((a) => a.confirmed && !a.validated);
      } else if (state == "validated") {
        filteredData = filteredData.filter(
          (a) => a.validated && !a.blacklisted
        );
      } else if (state == "blacklisted") {
        filteredData = filteredData.filter((a) => a.blacklisted);
      }
    }
  }

  onMount(async () => {
    try {
      const r = await API.get("admin/advisor");
      data = r.advisors;
    } catch (error) {
      console.error(e);
    }
    loading = false;
  });
  const username = (...args) => {
    return [...args].filter((el) => el).join(" ");
  };
  const color = (el) => {
    return el === 0 ? "disabled" : "primary";
  };
</script>

<span>
  <HeaderText>Gestionnaires</HeaderText>
</span>

<div id="filters">
  <section>
    <AppInput
      label="Filtre (prénom, nom, email, société)"
      bind:value={filter}
      crop
      placeholder="Prénom, nom, email, société"
      autofocus={true}
    />
  </section>
  <section>
    <AppText weight="bold">Etat :</AppText>
    <Spacing y="6" />
    <div class="themed">
      <Select
        items={allStates}
        isMulti={false}
        placeholder="Aucun état sélectionné"
        on:select={(e) => (state = e.detail.value)}
        on:clear={() => (state = null)}
      />
    </div>
  </section>
</div>

<LayoutContent {loading}>
  {#each filteredData as advisor}
    <section>
      <UserCard
        badge={State.advisor(advisor)}
        on:click={() => navigate(`/managers/${advisor.id}`)}
        company={advisor.company}
        username={username(advisor.firstName, advisor.lastName)}
      >
        <AppText size="small">nombre de datarooms</AppText>
        <AppDot color={color(advisor.clubDeals.length)}>
          {advisor.clubDeals.length}
        </AppDot>
      </UserCard>
    </section>
  {/each}
  <section />
  <section />
  <section />
</LayoutContent>

<style>
  span {
    display: block;
    @include respond-to("md") {
      display: none;
    }
  }
  section {
    width: 100%;
    @include respond-to("md") {
      width: calc(50% - 8px);
    }
    @include respond-to("lg") {
      width: calc(33% - 8px);
    }
    @include respond-to("xl") {
      width: 340px;
    }
  }
  div#filters {
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    border-bottom: 1px solid $gray;
    section {
      margin-bottom: 8px;
    }
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
</style>
