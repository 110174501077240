<script>
  import AppText from "../../components/Text/AppText.svelte";
  import AppBadge from "../../components/Badge/AppBadge.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import AppInput from "../../components/Form/Input.svelte";
  import Dialog, { Content } from "@smui/dialog";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  // import Select from "svelte-select";;
  import API from "../../utils/api";
  import State from "../../utils/functions/State";
  // import { STATE_LIST } from "../../constants";
  export let data;
  // let stateOption;
  let dialogValidate;
  let dialogBlacklist;
  let dialogDelete;
  let loading = false;
  // let stateloading = false;
  let snack;
  let snack_msg = "";
  let snack_color = "primary";
  // let statesList = [
  //   "En cours de souscription",
  //   "En cours de signature",
  //   "En cours de remboursement",
  //   "Terminé",
  // ];
  const showSnack = (msg, color = "primary") => {
    snack_msg = msg;
    snack_color = color;
    snack.forceOpen();
  };

  const onUpdate = async (name, val) => {
    try {
      await API.put("admin/advisor", { id: data.id, [name]: val });
      data[name] = val;
      showSnack("Les données ont été mises à jour");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    }
  };
  const onValidate = async () => {
    dialogValidate.close();
    if (loading) return;
    snack.close();
    loading = true;
    try {
      await API.post("admin/accountValidation", { id: data.id });
      data.validated = new Date();
      showSnack("Le compte a été validé");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      loading = false;
    }
  };
  const onAccountUnblacklisting = async () => {
    if (loading) return;
    loading = true;
    snack.close();
    try {
      await API.post("admin/accountUnblacklisting", { id: data.id });
      data.blacklisted = null;
      showSnack("Le compte a été débloqué");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      loading = false;
    }
  };
  const onBlacklist = async () => {
    dialogBlacklist.close();
    if (loading) return;
    loading = true;
    snack.close();
    try {
      await API.post("admin/accountBlacklisting", { id: data.id });
      data.blacklisted = new Date();
      showSnack("Le compte a été blacklisté");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      loading = false;
    }
  };
  const onDelete = async () => {
    dialogDelete.close();
    if (loading) return;
    loading = true;
    snack.close();
    try {
      await API.post("admin/accountDeletion", { id: data.id });
      data.deleted = new Date();
      showSnack("Le compte a été supprimé");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      loading = false;
    }
  };
  let firstName = data.firstName || "";
  let lastName = data.lastName || "";
  let phone = data.phone || "";
  let email = data.email || "";
  let company = data.company || "";

  $: date = State.advisor(data).value
    ? new Date(data[State.advisor(data).value]).toLocaleDateString("fr-FR")
    : "Date inconnue";
  // $: list = STATE_LIST.filter((el) => State.advisor(data).value !== el.value);
  $: is_in_confirmed_state = State.advisor(data).value === "confirmed";
  $: is_in_validated_state = State.advisor(data).value === "validated";
  $: is_in_blacklist_state = State.advisor(data).value === "blacklisted";
</script>

<AppSnackbar bind:value={snack} color={snack_color}>
  {snack_msg}
</AppSnackbar>
<Dialog bind:this={dialogValidate}>
  <Content style="width:400px;">
    <AppText color="blue" weight="900" size="large">Valider le compte?</AppText>
    <Spacing y="8" />
    <AppText>Êtes-vous sûr de vouloir valider le compte ?</AppText>
    <Spacing y="8" />
    <AppButton block on:click={onValidate}>Valider le compte</AppButton>
  </Content>
</Dialog>
<Dialog bind:this={dialogBlacklist}>
  <Content style="width:400px;">
    <AppText color="danger" weight="900" size="large">
      Bloquer le compte ?
    </AppText>
    <Spacing y="8" />
    <AppText>Êtes-vous sûr de vouloir bloquer le compte ?</AppText>
    <Spacing y="8" />
    <AppButton block color="danger" on:click={onBlacklist}
      >Bloquer le compte
    </AppButton>
  </Content>
</Dialog>
<Dialog bind:this={dialogDelete}>
  <Content style="width:400px;">
    <AppText color="danger" weight="900" size="large">
      Supprimer le compte ?
    </AppText>
    <Spacing y="8" />
    <AppText>Êtes-vous sûr de vouloir supprimer le compte ?</AppText>
    <Spacing y="8" />
    <AppButton block color="danger" on:click={onDelete}
      >Supprimer le compte
    </AppButton>
  </Content>
</Dialog>

<AppText size="large" color="blue" weight="bold">Etat du compte</AppText>
<Spacing y="8">
  <section>
    <AppBadge color={State.advisor(data).color} {date}>
      {State.advisor(data).text}
    </AppBadge>
    {#if is_in_confirmed_state}
      <AppButton {loading} block on:click={() => dialogValidate.open()}>
        Valider le compte
      </AppButton>
    {:else if is_in_validated_state}
      <AppButton
        {loading}
        color="danger"
        block
        on:click={() => dialogBlacklist.open()}
      >
        Bloquer le compte
      </AppButton>
    {:else if is_in_blacklist_state}
      <AppButton
        {loading}
        color="warning"
        block
        on:click={onAccountUnblacklisting}
      >
        Débloquer le compte
      </AppButton>
    {/if}
  </section>
</Spacing>

<Spacing y="8" />
<div>
  <AppText size="medium" color="blue" weight="900">Informations Gestionnaire</AppText
  >
  <Spacing y="8" />
  <section>
    <AppInput
      label="Nom"
      bind:value={lastName}
      on:blur={() => onUpdate("lastName", lastName)}
      crop
    />
    <AppInput
      label="Prénom"
      bind:value={firstName}
      on:blur={() => onUpdate("firstName", firstName)}
      crop
    />
  </section>
  <Spacing y="8" />
  <section>
    <AppInput
      label="Email"
      bind:value={email}
      on:blur={() => onUpdate("email", email)}
      crop
    />
    <AppInput
      label="Téléphone"
      bind:value={phone}
      on:blur={() => onUpdate("phone", phone)}
      crop
    />
  </section>
  <Spacing y="8" />
  <section>
    <AppInput
      label="Société"
      bind:value={company}
      on:blur={() => onUpdate("company", company)}
      crop
    />
  </section>
</div>

<AppButton {loading} color="danger" block on:click={() => dialogDelete.open()}>
  Supprimer le compte
</AppButton>

<style>
  section {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);
    @include respond-to("md"){
      grid-gap: 0;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
    }
  }
  div {
    padding: 16px;
    box-shadow: $primary-shadow;
    margin-bottom: 16px;
  }
</style>
