<script>
  /**
   * @constructor
   * Render a <Text></Text> Component
   */

  /**
   * @props  {string: "normal"} size : Size of the text, can take 7 values
   * - "xs"
   * - "small"
   * - "normal"
   * - "medium"
   * - "large"
   * - "xl"
   * - "xxl"
   */
  export let size = "normal";
  const SIZE_OPTION = ["xs", "small", "normal", "medium", "large", "xl", "xxl"];
  if (!SIZE_OPTION.includes(size)) {
    throw new Error("props 'size' in <Text> is undefined");
  }

  /** @props  {string: "normal"} color - weight of Text*/
  export let weight = "normal";

  /**
   * @props  {string: "primary"} color - color of Text
   * can take 10 values:
   * - "primary"
   * - "white"
   * - "secondary"
   * - "blue"
   * - "danger"
   * - "gray"
   * - "info"
   * - "progress"
   * - "warning"
   * - "validate"
   */
  export let color = "primary";

  const COLOR_OPTIONS = [
    "primary",
    "white",
    "secondary",
    "blue",
    "danger",
    "gray",
    "info",
    "progress",
    "warning",
    "validate",
  ];
  if (!COLOR_OPTIONS.includes(color)) {
    throw new Error(`props 'color' equal to '${color}' in <Text> is undefined`);
  }

  /** @props  {boolean: false} uppercase - render the element in Uppercase*/
  export let uppercase = false;

  /** @props  {boolean} lowercase - render the element in Lowercase*/
  export let lowercase = false;

  /** @props  {boolean} capitalize - render the element in Capitalize*/
  export let capitalize = false;

  export let center = false;
  /** @var {string} style : style of text, contains @var {String} color
   */
  let style = `font-weight: ${weight};`;
  /**
   * if my value of size is undefined:
   * @return {Error} : "props size in <Text>, option Undefined"
   */
</script>

<p
  class="c-text--{size} c-text--{color}"
  {style}
  class:center
  class:c-text--lowercase={lowercase}
  class:c-text--uppercase={uppercase}
  class:c-text--capitalize={capitalize}
>
  <slot />
</p>

<style lang="scss">
  p {
    font-family: "Lato";
    display: block;
    text-align: left;
    text-overflow: ellipsis;
    min-height: auto;
    overflow: hidden;
    min-width: auto;
    &.center {
      text-align: center;
    }
  }
  .c-text {
    &--primary {
      color: $color_primary;
    }
    &--secondary {
      color: $color_secondary;
    }
    &--blue {
      color: $blue;
    }
    &--white {
      color: $white;
    }
    &--info {
      color: $info;
    }
    &--validate {
      color: rgb(38, 233, 13);
    }
    &--warning {
      color: $warning;
    }
    &--danger {
      color: $danger;
    }
    &--gray {
      color: $gray_dark;
    }
    &--progress {
      color: $progress;
    }
    &--lowercase {
      text-transform: lowercase;
    }
    &--uppercase {
      text-transform: uppercase;
    }
    &--capitalize {
      text-transform: capitalize;
    }
    &--xs {
      font-size: 10px;
      line-height: 11.5px;
    }
    &--small {
      font-size: 11px;
      line-height: 1.1;
    }
    &--normal {
      font-size: 13px;
      line-height: 1.5;
    }
    &--medium {
      font-size: 16px;
      line-height: 1.5;
    }
    &--large {
      font-size: 18px;
      line-height: 1.2;
    }
    &--xl {
      font-size: 24px;
      line-height: 26px;
      position: relative;
      top: 3px;
    }
    &--xxl {
      font-size: 32px;
    }
  }
</style>
