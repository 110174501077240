<script>
  import { Row, Cell } from "@smui/data-table";
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import AppButton from "../../components/Button/AppButton.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import AppTable from "../../components/Table/AppTable.svelte";
  import AppBadge from "../../components/Badge/AppBadge.svelte";
  import API from "../../utils/api";
  export let data;
  // let loading = true;
  let list = [];
  // onMount(async () => {
  //   let clubdeals = [...data.subscriptions];
  //   clubdeals = clubdeals.map((id) => {
  //     return API.get("admin/cludeal", { id });
  //   });
  //   list = await Promise.all([...clubdeals]);
  //   console.log(list);
  //   // loading = false;
  // });
  const format = (nb) =>
    new Intl.NumberFormat("fr-FR", {
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: 2,
    }).format(nb);
  const formatDate = (
    ioDate = new Date(),
    options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  ) => {
    const date = new Date(ioDate);
    return date.toLocaleDateString("fr-FR", options);
  };
</script>

<AppTable
  header={["Club Deal", "Montant souscription", "Pourcentage", "Date de souscription"]}
>
  {#each data.subscriptions as sub}
    <Row>
      <Cell>
        <AppButton
          on:click={navigate(`/dossiers/${sub.bondId}`, { replace: true })}
          size="small"
          color="light"
        >
          <div>{sub.clubDeal.company.name}</div>
        </AppButton>
      </Cell>
      <Cell>
        <AppText weight="bold" color="blue">{format(sub.amount)} €</AppText>
      </Cell>
      <Cell>
        <AppText>{format((sub.amount / sub.clubDeal.amount) * 100)} %</AppText>
      </Cell>
      <!-- <Cell>
        <AppText>{format(2.8)} %</AppText>
      </Cell> -->

      <Cell>
        <AppText>{formatDate(sub.created)}</AppText>
      </Cell>
    </Row>
  {/each}
</AppTable>

<style>
  div {
    max-width: 200px;
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
