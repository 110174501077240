<script>
  export let to;
  import { Link } from "svelte-routing";

  const getProps = ({ href, isPartiallyCurrent, isCurrent }) => {
    // const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent
    const isActive = href === "/" ? false : isCurrent;

    // The object returned here is spread on the anchor element's attributes
    if (isActive) {
      return { class: "active" };
    }
    return {};
  };
</script>

<div>
  <Link {getProps} {to}><slot /></Link>
</div>

<style lang="scss">
  div {
    height: 100%;
    width: 100%;
    & > :global(a) {
      text-decoration: none;
      display: flex;
      height: 100%;
      width: calc(100% - 48px);
      padding: 0 24px;
      align-items: center;
      font-family: "Lato";
      color: $color_primary;
      font-size: 13px;
      transition: all ease 300ms;
      font-weight: 400;
      &:hover {
        background: $gray;
      }
      &.active {
        // color: $blue;
        font-weight: bold;
        // background: $blue;
        background: $gray;
      }
    }
  }
</style>
