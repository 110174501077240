<script>
  import LayoutVerticalNavbar from "./LayoutVerticalNavbar.svelte";
  import AppLink from "../Link/AppLink.svelte";
  import { navigate } from "svelte-routing";
  import { LOGO_PATH } from "../../constants";
  import Menu from "@smui/menu";
  import List, { Item, Separator, Text } from "@smui/list";
  import Button from "@smui/button";
  import NavLink from "../Link/NavLink.svelte";
  let menu;

  let nav_list = [["gestionnaires", "managers"], ["participants", "participants"], ["dossiers", "dossiers"]];
</script>

<div>
  <section class="mobile">
    <article>
      <a>
        <figure>
          <img src={LOGO_PATH} alt="logo" />
        </figure>
      </a>
      <span style="text-transform:capitalize">
        <Button on:click={() => menu.setOpen(true)}>
          menu
          <i class="material-icons"> arrow_drop_down </i>
        </Button>
        <Menu bind:this={menu}>
          <List>
            {#each nav_list as pair}
              <Item on:SMUI:action={() => navigate(`/${pair[1]}`)}>{pair[0]}</Item>
            {/each}
          </List>
        </Menu>
      </span>
    </article>
  </section>
  <section class="desktop">
    <LayoutVerticalNavbar>
      {#each nav_list as pair}
        <!-- <NavLink to={`/${item}`}>{item}</NavLink> -->
        <AppLink to={`/${pair[1]}`}>{pair[0]}</AppLink>
      {/each}
    </LayoutVerticalNavbar>
  </section>
  <main>
    <slot />
  </main>
</div>

<style>
  article {
    height: 48px;
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    box-shadow: $primary-shadow;
    justify-content: space-between;
    padding: 8px;
    position: relative;
    a {
      display: block;
      height: 100%;
      figure {
        max-width: 108px;
        height: 100%;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
  }
  div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    section {
      &.desktop {
        display: none;
        @include respond-to("md") {
          display: block;
        }
      }
      &.mobile {
        display: block;
        width: 100%;
        @include respond-to("md") {
          display: none;
        }
      }
    }
    main {
      padding: 16px;
      width: calc(100% - 32px);
      margin-left: 0px;
      @include respond-to("md") {
        margin-left: 224px;
      }
    }
  }
</style>
