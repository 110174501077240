<script>
  export let src;
  export let size = "normal";
  export let onerror;
  export let style;

  const SIZES_OPTIONS = ["normal", "medium", "large", "xl", "xxl"];
  if (!SIZES_OPTIONS.includes(size)) {
    throw new Error(
      `Invalide value: the props value of 'size' is equal to '${size}' in <AppImg> component. The validates values are: 'normal' and 'large'`
    );
  }
</script>

<figure class="c-img--{size}">
  <img {src} alt="" {onerror} {style} />
</figure>

<style>
  figure {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: $primary-shadow;
    &.c-img {
      &--normal {
        min-width: 64px;
        min-height: 64px;
      }
      &--medium {
        min-width: 72px;
        min-height: 72px;
      }
      &--large {
        min-width: 88px;
        min-height: 88px;
      }
      &--xl {
        min-width: 96px;
        min-height: 96px;
      }
      &--xxl {
        min-width: 112px;
        min-height: 112px;
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
