<script>
  import AppBadge from "../Badge/AppBadge.svelte";
  import AppImg from "../Img/AppImg.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  import AppText from "../Text/AppText.svelte";
  import { createEventDispatcher } from "svelte";
  import AppDot from "../Dot/AppDot.svelte";

  export let badge = {
    color: "primary",
    text: "En cours de validation",
  };
  export let company = "company name";
  export let username = "manager name";
  const dispatch = createEventDispatcher();
  const onClick = () => {
    dispatch("click");
  };
</script>

<div on:click={onClick}>
  <AppBadge color={badge.color}>{badge.text}</AppBadge>
  <Spacing y="8" />
  <section>
    <aside>
      <header>
        <AppText color="blue" weight="bold" size="large">
          {company || "Inconnu"}
        </AppText>
        <AppText size="small" weight="bold">{username || "inconnu"}</AppText>
      </header>
      <main>
        <slot />
      </main>
    </aside>
  </section>
</div>

<style>
  div {
    cursor: pointer;
    box-shadow: $primary-shadow;
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 4px;
    display: block;
    section {
      display: flex;
      aside {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 88px);
        header {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      main {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
</style>
