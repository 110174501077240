<script>
  import { i18n, formatNumber } from "../../stores/i18n";
  import { Cell, Row } from "@smui/data-table";
  import AppText from "../../components/Text/AppText.svelte";
  import AppTable from "../../components/Table/AppTable.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  let header = ["Date de l'évènement", "Type d'évènement", "Compte", "Cible de l'événement"];
  export let data;

  const getEventName = (eventType) => {
    switch (eventType) {
      case "bondRead":
        return $i18n("Accès à la dataroom", "Dataroom access");
      case "documentCreated":
        return $i18n("Création d'un document", "Document created");
      case "documentRead":
        return $i18n("Téléchargement d'un document", "Document download");
      case "documentUpdated":
        return $i18n("Mise à jour d'un document", "Document updated");
      case "invitationSent":
        return $i18n("Envoi d'une invitation", "Invitation sent");
      case "bondCreated":
        return $i18n("Création d'une dataroom", "Dataroom created");
      case "bondUpdated":
        return $i18n("Mise à jour d'une dataroom", "Dataroom updated");
      case "bondRead":
        return $i18n("Consultation d'une dataroom", "Dataroom accessed");
      case "emailCreated":
        return $i18n("Création d'un email", "Email created");
      case "emailUpdated":
        return $i18n("Mise à jour d'un email", "Email updated");
      case "emailSent":
        return $i18n("Envoi d'un email", "Email sent");
      case "loginSuccess":
        return $i18n("Authentification réussie", "Login success");
      case "loginFailure":
        return $i18n("Echec d'authentification", "Login failure");
    }
  };

  const FindSubscriber = (event) => {
    return data.clubDealInvestors.find((el) => el.id === event.accountId);
  };
  const FindCoAdvisor = (event) => {
    return data.clubDealCoAdvisors.find((el) => el.id === event.accountId);
  };
  const getAccountName = (event) => {
    let s = FindSubscriber(event);
    if (s){
      return `${s.firstName} ${s.lastName}`;
    } else if (event.accountId == data.clubDealAdvisor.id){
      return `${data.clubDealAdvisor.firstName} ${data.clubDealAdvisor.lastName} (gestionnaire)`;
    } else {
      s = FindCoAdvisor(event);
      if (s){
        return `${s.firstName} ${s.lastName} (co-gestionnaire)`;
      } else {
        return $i18n("Inconnu", "Unknown");        
      }
    }    
  };
</script>

<Spacing y="8" />
<AppTable {header}>
  {#each data.events as event}
    <Row>
      <Cell>
        <AppText>{new Date(event.date).toLocaleDateString("fr-Fr", {
          month: "long",
          year: "numeric",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })}</AppText>
      </Cell>
      <Cell>{getEventName(event.eventType)}</Cell>
      <Cell>{getAccountName(event)}</Cell>
      <Cell>{event.detail == ""
        ? $i18n("Inconnu", "Unknown")
        : event.detail}</Cell>
    </Row>
  {/each}
</AppTable>
