<script>
  import LayoutContent from "../../components/Layout/LayoutContent.svelte";
  import { Cell, Row } from "@smui/data-table";
  import AppButton from "../../components/Button/AppButton.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import AppTable from "../../components/Table/AppTable.svelte";
  import AppBadge from "../../components/Badge/AppBadge.svelte";
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import API from "../../utils/api";

  export let data;
  let loading = true;
  let list = [];
  onMount(async () => {
    let clubdeals = [...data.clubDeals];
    clubdeals = clubdeals.map((id) => {
      return API.get("admin/clubdeal", { id });
    });
    list = await Promise.all([...clubdeals]);
    console.log(list);
    loading = false;
  });
  let header = [
    "Nom du dossier",
    "Montant",
    "taux",
    "durée",
    "Nbr participants",
    "Etat",
  ];
  const format = (nb) =>
    new Intl.NumberFormat("fr-FR", {
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: 4,
    }).format(nb);
  const stateProps = (state) => {
    return (
      {
        notSubmitted: {
          text: "Non soumis",
          color: "disabled",
        },
        subscription: {
          text: "En cours de souscription",
          color: "primary",
        },
        signature: {
          text: "En cours de signature",
          color: "progress",
        },
        running: {
          text: "En cours de remboursement",
          color: "progress",
        },
      }[state] || { text: ["Terminé", "Completed"], color: "disabled" }
    );
  };
  $: empty = list.length === 0;
</script>

<LayoutContent {loading}>
  <AppTable {empty} {header}>
    {#each list as cd}
      <Row>
        <Cell>
          <AppButton
            on:click={navigate(`/dossiers/${cd.id}`, { replace: true })}
            size="small"
            color="light"
          >
            <div>{cd.company.name}</div>
          </AppButton>
        </Cell>
        <Cell>
          <AppText>{format(cd.amount)} €</AppText>
        </Cell>
        <Cell>
          <AppText>{format(cd.rate)} %</AppText>
        </Cell>
        <Cell>
          <AppText>{cd.maturity} mois</AppText>
        </Cell>
        <Cell>
          <AppText>{cd.clubDealInvestorsEmails.length}</AppText>
        </Cell>
        <Cell>
          <AppBadge color={stateProps(cd.state).color}>
            {stateProps(cd.state).text}
          </AppBadge>
        </Cell>
      </Row>
    {/each}
  </AppTable>
</LayoutContent>

<style>
  div {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
</style>
