<script>
  /**@props {String: "0"} y: vertical spacing **/
  export let y = "0";

  /**@props {String: "0"} x: horizontal spacing **/
  export let x = "0";

  /**@props {String: "0"} divider: horizontal spacing **/
  export let divider = false;

  /**@props {Object: {top:"0", bottom: "0"}} divider_props: divider spacing contain:
   * @params {String: "0"} top: vertical divider spacing
   * @params {String: "0"} bottom: horizontal divider spacing **/
  export let divider_props = {
    top: "0",
    bottom: "0",
  };
  const style = `
  padding:${y}px ${x}px;
  `;
  const divider_style = `
      margin-top: ${divider_props.top}px;
      margin-bottom: ${divider_props.bottom}px;
  `;
</script>

<div {style}>
  <slot />
  {#if divider}
    <span style={divider_style} />
  {/if}
</div>

<style>
  span {
    display: block;
    min-width: 100%;
    border-bottom: 1px solid $gray;
  }
</style>
