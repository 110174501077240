<script>
  import LayoutHeaderAccount from "../components/Layout/LayoutHeaderAccount.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import Informations from "./RouteSubscribersDetails/Informations.svelte";
  import ClubsDeals from "./RouteSubscribersDetails/ClubsDeals.svelte";
  import State from "../utils/functions/State";
  import { onMount } from "svelte";
  import API from "../utils/api";

  export let id;
  let data = {};
  let loading = true;

  let active = "informations";
  let tabs = [
    { value: "informations", component: Informations },
    { value: "accès dossiers", component: ClubsDeals },
  ];
  const getSubscriberById = async (id, callback) => {
    try {
      const response = await API.get("admin/subscriber", { id });
      callback(response);
    } catch (e) {
      console.error(e);
    }
  };
  const getKycById = async (id, callback) => {
    try {
      const response = await API.get("admin/kyc", { id });
      callback(response);
    } catch (e) {
      console.error(e);
    }
  };
  onMount(async () => {
    await getSubscriberById(id, (r) => {
      data = r;
      // console.log(r);
    });
    await getKycById(id, (r) => {
      data.kyc = r;
      // console.log(r);
    });
    loading = false;
  });
  $: component = tabs.find((el) => el.value === active).component;
</script>

<LayoutHeaderAccount {loading} name={data.firstName+" "+data.lastName} state={State.subscriber(data)}>
  <TabBar tabs={["informations", "accès dossiers"]} let:tab bind:active>
    <Tab {tab}>
      <Label>{tab}</Label>
    </Tab>
  </TabBar>
  <Spacing y="12" />
  <svelte:component this={component} bind:data={data} />
  <Spacing y="4" />
</LayoutHeaderAccount>
