<script>
  import DataTable, { Body, Head, Row, Cell } from "@smui/data-table";
  import AppText from "../Text/AppText.svelte";
  const cell_style = `
  text-align:center;
  background: rgb(226, 226, 226);
  color: rgb(180, 180, 180);
  font-weight: 500;
  font-family: "Lato";
  font-weight: 700;
  `;
  export let header = [];
  export const empty_message = "data vide";
  export let empty = false;
  export let empty_msg = "Aucun element trouvé";
</script>

<DataTable class="table">
  <Head>
    <Row>
      {#each header as cell}
        <Cell>
          <AppText weight="700">{cell}</AppText>
        </Cell>
      {/each}
    </Row>
  </Head>

  <Body>
    {#if !empty}
      <slot />
    {:else}
      <Row>
        <Cell style={cell_style} colspan={header.length}>
          <p>{empty_msg}</p>
        </Cell>
      </Row>
    {/if}
  </Body>
</DataTable>

<style>
  :global(.mdc-data-table.table) {
    width: 100%;
  }
</style>
