<script>
  import LayoutHeaderAccount from "../components/Layout/LayoutHeaderAccount.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import Informations from "./RouteAdvisorsDetails/Informations.svelte";
  import ClubsDeals from "./RouteAdvisorsDetails/ClubsDeals.svelte";
  import { onMount } from "svelte";
  import API from "../utils/api";
  import State from "../utils/functions/State";
  export let id;
  let data = {};
  let loading = true;
  let active = "informations";
  let tabs = [
    { value: "informations", component: Informations },
    { value: "dossiers", component: ClubsDeals },
  ];
  const getAdvisorById = async (id, callback) => {
    try {
      const response = await API.get("admin/advisor", { id });
      callback(response);
      loading = false;
    } catch (e) {
      console.error(e);
    }
  };
  onMount(() => {
    getAdvisorById(id, (r) => {
      data = r;
      console.log(r);
    });
  });
  $: component = tabs.find((el) => el.value === active).component;
</script>

<LayoutHeaderAccount {loading} name={data.company} state={State.advisor(data)}>
  <TabBar tabs={["informations", "dossiers"]} let:tab bind:active>
    <Tab {tab}>
      <Label>{tab}</Label>
    </Tab>
  </TabBar>
  <Spacing y="12" />
  <svelte:component this={component} bind:data={data} />
  <Spacing y="8" />
</LayoutHeaderAccount>
