<script>
  import { LOGO_PATH } from "../../constants";
  import { navigate } from "svelte-routing";
  import { LOGIN_PATH } from "../../constants";
  import AppButton from "../Button/AppButton.svelte";
  import { login } from "../../stores/login";
  import { profile } from "../../stores/profile";

  function disconnect_user() {
    login.set(false);
    profile.set({});
    navigate(LOGIN_PATH, { replace: true });
  }
</script>

<div>
  <a>
    <figure>
      <img src={LOGO_PATH} alt="logo" />
    </figure>
  </a>
  <main>
    <nav>
      <slot />
    </nav>
    <section>
      <AppButton on:click={disconnect_user} color="danger" block>
        Se déconnecter
      </AppButton>
    </section>
  </main>
</div>

<style>
  /* span {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
  } */
  div {
    background: $gray-light;
    width: 224px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    a {
      display: block;
      border-bottom: 1px solid $gray;
      figure {
        width: calc(100% - 8px);
        padding: 22px 4px;
        height: 46px;
        display: flex;
        justify-content: center;
        img {
          width: 70%;
        }
      }
    }
    main {
      height: calc(100% - 85px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      nav {
        text-transform: capitalize;
      }
      section {
        padding: 24px;
      }
    }
  }
</style>
