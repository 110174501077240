<script>
  import { Router, Route, navigate } from "svelte-routing";
  import { routes } from "./routes";
  import { LOGIN_PATH } from "./constants";
  import LayoutMain from "./components/Layout/LayoutMain.svelte";
  import { onMount } from "svelte";
  import { login } from "./stores/login";
  import RouteLogin from "./routes/RouteLogin.svelte";
  onMount(() => {
    if (!$login) {
      console.log("oui");
      navigate(LOGIN_PATH, { replace: true });
    }
  });
</script>

<Router url="">
  {#if $login}
    <LayoutMain>
      {#each routes as { path, component }}
        <Route {path} {component} />
      {/each}
    </LayoutMain>
  {:else}
    <Route path="/login" component={RouteLogin} />
  {/if}
</Router>

<style>
  :global(.themed) {
    --placeholderColor: rgb(180, 180, 180);
    --height: 48px;
    --border: 1px solid rgb(170, 170, 170);
    --itemIsActiveBG: rgb(21, 21, 255);
    --borderFocusColor: rgb(21, 21, 255);
    --itemHoverBG: rgb(230, 230, 255);
    --multiItemMargin: 8px 6px 0 0;
    --multiItemActiveBG: rgb(21, 21, 255);
    font-family: "Lato";
    --padding: 0;
    :global(.selectContainer),
    :global(.selectContainer input) {
      --padding: 0 8px;
    }
  }
</style>
