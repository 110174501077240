<script>
  import { createEventDispatcher } from "svelte";
  import NavLink from "./NavLink.svelte";

  export let to;
  const dispatch = createEventDispatcher();
</script>

<div>
  <NavLink {to}>
    <p><slot /></p>
    <aside><slot name="right" /></aside>
  </NavLink>
</div>


<style>
  div {
    display: block;
    /* padding: 0 24px; */
    height: 48px;
    border-bottom: thin solid $gray;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    transition: all ease 200ms;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    p {
      width: 100px;
    }
    aside {
      width: calc(100% - 100px);
    }
  }
</style>
