<script>
  import API from "../../utils/api";
  import { onMount } from "svelte";
  import { Cell, Row } from "@smui/data-table";
  import AppButton from "../../components/Button/AppButton.svelte";
  import AppText from "../../components/Text/AppText.svelte";
  import AppTable from "../../components/Table/AppTable.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";

  export let data;

  let header = ["Fichier", "Actions"];
  let documents = [];
  let file_input;
  let loading_doc = false;

  onMount(async () => {
    try {
      documents = (
        await API.get("admin/document", { bondId: data.id })
      ).documents
        .map((document) => addDocument(document))
        .reverse();
      console.log(documents);
    } catch (e) {
      console.error(e);
    }
  });

  const openFile = () => {
    file_input.click();
  };
  function addDocument(document) {
    return { document, loading: false, del: false };
  }
  const onInput = async (e) => {
    try {
      loading_doc = true;
      const file = e.target.files[0];
      // snack.close();
      let document = await API.post("admin/document", {
        fileName: file.name,
        contentType: file.type,
        bondId: data.id,
      });
      documents = [...documents, addDocument(document)];
      // documents = documents; // to trigger svelte reactivity
      await fetch(newDoc.url, { method: "PUT", body: file });
      // svelte bug: without timeout, snack is null
      // setTimeout(() => {
      //   snackSaved.forceOpen();
      // }, 100);
    } catch (e) {
      console.log(e);
      // svelte bug: without timeout, snack is null
      // setTimeout(() => {
      //   snack.forceOpen();
      // }, 100);
    } finally {
      loading_doc = false;
    }
  };

  const onDelete = async (i) => {
    if (documents[i].del) return;
    documents[i].del = true;
    try {
      console.log(documents[i].document);
      await API.del("admin/document", { id: documents[i].document.id });
      documents = documents.filter((_, b) => b !== i);
    } catch (e) {
      console.error(e);
      documents[i].del = false;
    } finally {
      documents[i].del = false;
    }
  };
  async function onDownload(doc, i) {
    if (documents[i].loading) return;
    documents[i].loading = true;
    try {
      let freshDoc = await API.get("admin/document", {
        bondId: data.id,
        id: doc.id,
      });
      let response = await fetch(freshDoc.url, { method: "GET" });
      // download file
      var newBlob = new Blob([await response.blob()], {
        type: freshDoc.contentType,
      });
      const url = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = url;
      link.download = freshDoc.fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    } catch (e) {
      console.error(e);
    } finally {
      documents[i].loading = false;
    }
  }

  $: empty = documents.length === 0;
</script>

<AppButton on:click={openFile} loading={loading_doc}>
  Ajouter un document
</AppButton>
<input
  style="display:none;"
  type="file"
  on:input={onInput}
  bind:this={file_input}
/>
<Spacing y="8" />
<AppTable {empty} {header}>
  {#each documents as { document, loading, del }, i}
    <Row>
      <Cell>
        <AppText>{document.fileName}</AppText>
      </Cell>
      <Cell>
        <section>
          <AppButton
            {loading}
            icon
            color="light"
            on:click={() => onDownload(document, i)}
          >
            file_download
          </AppButton>
          <Spacing x="8" />
          <AppButton
            icon
            color="alert"
            loading={del}
            on:click={() => onDelete(i)}
          >
            close
          </AppButton>
        </section>
      </Cell>
    </Row>
  {/each}
</AppTable>

<style>
  /* div {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  } */
  section {
    display: flex;
  }
</style>
