<script>
  import { LOGO_PATH } from "../../constants";
  import AppText from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
</script>

<!-- <AppContent> -->
<div>
  <main>
    <figure>
      <img src={LOGO_PATH} alt="logo" />
    </figure>
    <section>
      <span>
        <AppText size="xl" weight="900">Connexion</AppText>
      </span>
      <slot />
    </section>
  </main>
</div>

<style>
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    main {
      width: calc(100% - 32px);
      margin: 0 16px;
      @include respond-to('md'){
        width: 440px;
        margin: 0;
      }
      background-color: $white;
      box-shadow: $secondary-shadow;
      padding: 24px;
      border-radius: 4px;
      figure {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          height: 46px;
        }
      }
      section {
        margin: 0 8px;
        @include respond-to('md'){
          margin: 0 40px;
        }
        span {
          display: block;
          margin: 24px 0;
        }
      }
    }
  }
</style>
