<script>
  import Spacing from "../components/Spacing/Spacing.svelte";
  import CardClubDeal from "../components/Card/CardClubDeal.svelte";
  import HeaderText from "../components/Text/HeaderText.svelte";
  import AppText from "../components/Text/AppText.svelte";
  import AppInput from "../components/Form/Input.svelte";
  import Select from "svelte-select";
  import AppDot from "../components/Dot/AppDot.svelte";
  import UserCard from "../components/Card/UserCard.svelte";
  import LayoutContent from "../components/Layout/LayoutContent.svelte";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  // import State from "../utils/functions/State";
  import API from "../utils/api";
  let loading = true;
  let data = [];
  let filteredData = [];
  let filter = "";
  let allStates = [
    {
      value: "notSubmitted",
      label: "Non soumis",
    },
    {
      value: "toBeLaunched",
      label: "A lancer",
    },
    {
      value: "subscription",
      label: "Souscription",
    },
    {
      value: "signature",
      label: "Signature",
    },
    {
      value: "running",
      label: "En cours",
    },
    {
      value: "mature",
      label: "Clôturé",
    },
    {
      value: "launched",
      label: "Lancé",
    },
    {
      value: "suspended",
      label: "Suspendu",
    },
  ];
  let state = null;
  $: {
    filteredData = data;
    if (filter.trim().length) {
      let filters = filter
        .split(" ")
        .filter((e) => e.length)
        .map((e) => e.toLowerCase());
      filteredData = data.filter((cd) => {
        const str = `${cd.name} ${cd.company.name}`.toLowerCase();
        return filters.every((f) => str.indexOf(f) > -1);
      });
    }
    if (state) {
      filteredData = filteredData.filter((cd) => cd.state == state);
    }
  }
  const getAdvisor = async (callback) => {
    try {
      const response = await API.get("admin/clubdeal");
      callback(response);
      loading = false;
    } catch (e) {
      console.error(e);
    }
  };
  onMount(() => {
    getAdvisor((r) => {
      data = r.clubDeals;
      console.log(data);
    });
  });
  const username = (...args) => {
    return [...args].filter((el) => el).join(" ");
  };
  const color = (el) => {
    return el === 0 ? "disabled" : "primary";
  };
  const format = (nb) =>
    new Intl.NumberFormat("fr-FR", {
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: 4,
    }).format(nb || 0);
  
    function getName(cd){
    if (cd.name && cd.name.length){
      return cd.name
    }
    if (cd.company.name && cd.company.name.length){
      return cd.company.name
    }
    return "Inconnu"
  }
</script>

<span>
  <HeaderText>Datarooms</HeaderText>
</span>

<div id="filters">
  <section>
    <AppInput
      label="Filtre (nom de dataroom, nom de société)"
      bind:value={filter}
      crop
      placeholder="Nom de dataroom, nom de société"
      autofocus={true}
    />
  </section>
  <section>
    <AppText weight="bold">Etat :</AppText>
    <Spacing y="6" />
    <div class="themed">
      <Select
        items={allStates}
        isMulti={false}
        placeholder="Aucun état sélectionné"
        on:select={(e) => (state = e.detail.value)}
        on:clear={() => (state = null)}
      />
    </div>
  </section>
</div>
<LayoutContent {loading}>
  {#each filteredData as cd}
    <section>
      <CardClubDeal
        on:click={() => navigate(`/dossiers/${cd.id}`)}
        id={cd.id}
        state={cd.state}
        rate={format(cd.rate)}
        duration={format(cd.maturity)}
        companyName={getName(cd)}
        instrument={cd.instrument}
        typology={cd.typology}
        amount={cd.amount}
      />
    </section>
  {/each}
  <section />
  <section />
  <section />
</LayoutContent>

<style>
  span {
    display: block;
    @include respond-to("md") {
      display: none;
    }
  }
  section {
    width: 100%;
    @include respond-to("md") {
      width: calc(50% - 8px);
    }
    @include respond-to("lg") {
      width: calc(33% - 8px);
    }
    @include respond-to("xl") {
      width: 340px;
    }
  }
  div#filters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    border-bottom: 1px solid $gray;
    section {
      margin-bottom: 8px;
    }
    padding-bottom: 8px;
    margin-bottom: 16px;
  }
</style>
