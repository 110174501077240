<script>
  /**@props {String: "primary"} color: color of Dot.
   * * primary [default]
   * * danger
   */
  export let color = "primary";
</script>

<p class={color}><slot /></p>

<style>
  p {
    width: 21px;
    height: 21px;
    border-radius: 3px;
    background: $blue;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    font-variant: proportional-nums;
    &.primary {
      background: $blue;
    }
    &.danger {
      background: $danger;
    }
    &.disabled {
      color: #828282;
      background: #e0e0e0;
    }
  }
</style>
