<script>
  //import Dialog, { Content } from "@smui/dialog";
  //import AppLogin from "../components/Layout/AppLogin.svelte";
  import Input from "../components/Form/Input.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import AppButton from "../components/Button/AppButton.svelte";
  import LayoutLogin from "../components/Layout/LayoutLogin.svelte";
  import { EMAIL_RULE } from "../constants";
  import { navigate } from "svelte-routing";
  import { delay } from "../utils/functions/delay";
  import { profile } from "../stores/profile";
  import { login } from "../stores/login";
  import API from "../utils/api";
  import Appsnackbar from "../components/Snackbar/AppSnackbar.svelte";

  let email = "";
  let password = "";
  // let email = "thomas.deruel.admin@firmfunding.com";
  // let password = "Ag5YuLdev";

  let loading = false;
  let loadingDialog = false;
  let error = false;
  let errorDialog = false;
  let mfa = "";
  let count = 5;
  let snack;
  let snack_forgot_pw;
  let dialog;
  let getEmailForgotpw = "";
  let message_forget_pw = "";
  $: disabled = email === "" || password === "" || !EMAIL_RULE.test(email);
  $: error_message = `Erreur ( ${count} s.)`;
  $: disabledDialog = !EMAIL_RULE.test(getEmailForgotpw);

  // TODO:
  // ajouter vrai point d'entrée
  // const onSendForgotPassword = async () => {
  //   try {
  //     if (loadingDialog) return;
  //     loadingDialog = true;
  //     message_forget_pw = "";
  //     if (errorDialog) {
  //       errorDialog = false;
  //     }
  //     if (snack_forgot_pw) {
  //       snack_forgot_pw.close();
  //     }
  //     // TODO:
  //     // à remplacer par api
  //     await new Promise((resolve) => {
  //       setTimeout(resolve, 2000);
  //     });
  //     // TODO:
  //     // changer wording
  //     message_forget_pw = `un nouveau mot de passe vous a été transféré à l'adresse suivante: ${getEmailForgotpw}.`;
  //   } catch (e) {
  //     console.error(e);
  //     errorDialog = true;
  //     // TODO:
  //     // changer wording
  //     message_forget_pw = "Une erreur est survenue. Veuillez recommencer.";
  //   } finally {
  //     loadingDialog = false;
  //     dialog.close();
  //     snack_forgot_pw.forceOpen();
  //   }
  // };
  const onLogin = async () => {
    if (loading || error) return;
    loading = true;
    try {
      const mine = new Function(await API.get("login", { email }))();
      const response = await API.post("login", {
        email,
        password,
        ...mine(password),
        mfa,
      });
      console.log(response);
      if (response.profile.type !== "admin") {
        throw new Error("you're not");
      }
      profile.set(response.profile);
      login.set(true);
      navigate("/", { replace: true });
    } catch (e) {
      console.error(e);
      snack.forceOpen();
      error = true;
      await delay(3000, (time_left) => (count = time_left));
      error = false;
    } finally {
      loading = false;
    }
  };
</script>

<LayoutLogin>
  <!-- <aside style="width:600px;">
  <Dialog bind:this={dialog}>
    <Content>
      <AppText color="blue" size="large" weight="900"
        >Changer de mot de passe</AppText
      >
      <Spacing y="12" />
      <AppText
        >Vous avez oublié votre mot de passe? Saisissez votre adresse mail
        ci-dessous.
      </AppText>
      <Spacing y="8" />
      <Input
        uppercase
        rules={[EMAIL_RULE]}
        message_error={$i18n("Email invalide", "Invalid email")}
        label={$i18n("Email", "Email")}
        bind:value={getEmailForgotpw}
      />
      <AppButton
        loading={loadingDialog}
        on:click={onSendForgotPassword}
        disabled={disabledDialog}>Modifier le mot de passe</AppButton
      >
    </Content>
  </Dialog>
</aside> -->
  <!-- <AppLogin> -->
  <Appsnackbar
    bind:value={snack}
    timeoutMs={6000}
    color="danger"
    labelText="L'adresse email ou le mot de passe est invalide, veuillez recommencer."
  />
  <!--<Appsnackbar
    bind:value={snack_forgot_pw}
    timeoutMs={6000}
    color={errorDialog ? "danger" : "primary"}
    labelText={message_forget_pw}
  /> -->
  <Input
    uppercase
    rules={[EMAIL_RULE]}
    message_error="Email Invalide"
    label="Email"
    required
    bind:value={email}
  />
  <Input
    uppercase
    crop
    label="Mot de Passe"
    required
    type="password"
    bind:value={password}
  />
  <input name="mfa" bind:value={mfa} />
  <Spacing y="12" />
  <div>
    <AppButton
      bind:loading
      bind:disabled
      bind:error
      width="120px"
      on:click={onLogin}
      {error_message}>Se connecter</AppButton
    >
    <!-- <AppLink on:click={dialog.open()}>
      Mot de passe oublié ?
    </AppLink> -->
  </div>
  <!-- </AppLogin> -->
</LayoutLogin>

<style>
  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  input {
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }
</style>
