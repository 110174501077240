/**
 * @class State - return current State (check AppBadge.svelte)
 */
class State {
    /**@method advisor(Object) - return advisor state */
    advisor(data) {
        if (data.blacklisted) {
            return {
                text: "Compte bloqué",
                color: "disabled",
                value: "blacklisted"
            }
        }
        if (data.validated) {
            return {
                text: "Compte validé",
                color: "primary",
                value: "validated"
            }
        }
        if (data.confirmed) {
            return {
                text: "Compte à valider",
                color: "danger",
                value: "confirmed"
            }
        }
        if (data.confirmed === null) {
            return {
                text: "Non confirmé",
                color: "disabled",
                value: null
            }
        }
    }
    /**@method subscriber(Object) - return subscriber state */
    subscriber(data) {
        if (data.blacklisted) {
            return {
                text: "Compte bloqué",
                color: "disabled",
                value: "blacklisted"
            }
        }
        if (data.validated) {
            return {
                text: "Compte validé",
                color: "primary",
                value: "validated"
            }
        }
        if (data.confirmed) {
            return {
                text: "Compte confirmé",
                color: "primary",
                value: "confirmed"
            }
        }
        if (data.confirmed === null) {
            return {
                text: "Non confirmé",
                color: "disabled"
            }
        }
    }
    /**@method subscriber(String) - return clubdeal state */
    clubdeal(state) {
        switch(state) {
            case "subscription":
                return {
                    text: "En cours de souscription",
                    color: "primary"
                }
            case "signature":
                return {
                    text: "En cours de signature",
                    color: "progress"
                }
            case "toBeLaunched":
                return {
                    text: "A lancer",
                    color: "danger"
                }
            case "running":
                return {
                    text: "En cours de remboursement",
                    color: "progress"
                }
            case "notSubmitted":
                return {
                    text: "Non soumis",
                    color: "progress"
                }
            case "suspended":
                return {
                    text: "Suspendu",
                    color: "disabled"
                }
            default:
                return {
                    text: "Terminé",
                    color: "disabled"
                }
        }
    }
}
export default new State();
// stateProps = {
//     subscription: {
//       text: ["En cours de souscription", "Under subscription"],
//       color: "primary",
//     },
//     signature: {
//       text: ["En cours de signature", "In the process of being signed"],
//       color: "progress",
//     },
//     running: {
//       text: [
//         "En cours de remboursement",
//         "In the process of being reimbursed",
//       ],
//       color: "progress",
//     },
//   }[clubDeal.state] || { text: ["Terminé", "Completed"], color: "disabled" };
// }

// if(data.blacklisted !== null){
//     return {name:"Bloqué", color:'_gray'}
//   }

//   if(data.validated !== null){
//     return {name:"validé", color:'_blue'}
//   }

//   if(data.confirmed !== null ){
//     return {name:"A valider", color:'_red'}
//   }

//   if(data.confirmed === null){
//     return {name:"Non confirmé",color:'_gray'};
//   }
// },



//subscribers
// if(val.blacklisted !== null){
//     return "Bloqué"
//   }

//   if(val.validated !== null){
//     return "Validé"
//   }

//   if(val.toBeValidated){
//     return "A valider"
//   }

//   if(val.confirmed){
//     return "NDA non signé"
//   }
//   if(val.confirmed === null){
//     return "Non confirmé"
//   }
// },


// dossier cd
// switch(val) {
//     case 'subscription':
//       return "Souscription"
//     case 'launched':
//       return "Lancé"
//     case 'signature':
//       return "Signature"
//     case 'notSubmitted':
//       return "Non soumis"
//     case 'mature':
//       return "Maturité"
//     case 'running':
//       return "En cours"
//     case 'toBeLaunched':
//       return "A lancer"
//     case 'suspended':
//       return "Suspendu"
//     default:
//       return "Etat inconnu"
//   }