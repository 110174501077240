<script>
  import { onMount } from "svelte";
  import TabBar from "@smui/tab-bar";
  import Tab, { Label } from "@smui/tab";
  import API from "../utils/api";
  import State from "../utils/functions/State";
  import LayoutHeaderAccount from "../components/Layout/LayoutHeaderAccount.svelte";
  import Spacing from "../components/Spacing/Spacing.svelte";
  import Informations from "./RouteClubdsDealsDetails/Informations.svelte";
  import Documents from "./RouteClubdsDealsDetails/Documents.svelte";
  import Subscriptions from "./RouteClubdsDealsDetails/Subscriptions.svelte";
  import Subscribers from "./RouteClubdsDealsDetails/Subscribers.svelte";
  import Log from "./RouteClubdsDealsDetails/Log.svelte";
  import Advisors from "./RouteClubdsDealsDetails/Advisors.svelte";
  export let id;
  export let data = {};
  let loading = true;
  let active = "informations";
  let tabs = [
    { value: "informations", component: Informations },
    { value: "documents", component: Documents },
    // { value: "souscriptions", component: Subscriptions },
    { value: "participants", component: Subscribers },
    { value: "gestionnaires", component: Advisors },
    { value: "journal", component: Log },
  ];
  onMount(async () => {
    try {
      data = await API.get("admin/clubdeal", { id });
      console.log(data);
      loading = false;
    } catch (e) {
      console.error(e);
    }
  });
  $: component = tabs.find((el) => el.value === active).component;
</script>

<LayoutHeaderAccount
  {loading}
  name={data.name || (data.company && data.company.name)}
  state={State.clubdeal(data.state)}
>
  <TabBar
    tabs={[
      "informations",
      "documents",
      // "souscriptions",
      "participants",
      "gestionnaires",
      "journal",
    ]}
    let:tab
    bind:active
  >
    <Tab {tab}>
      <Label>{tab}</Label>
    </Tab>
  </TabBar>
  <Spacing y="12" />
  <svelte:component this={component} bind:data />
  <Spacing y="8" />
</LayoutHeaderAccount>
