<script>
  import AppText from "../../components/Text/AppText.svelte";
  import AppBadge from "../../components/Badge/AppBadge.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import { Cell, Row } from "@smui/data-table";
  import AppTable from "../../components/Table/AppTable.svelte";
  import AppInput from "../../components/Form/Input.svelte";
  import Dialog, { Content } from "@smui/dialog";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  import { navigate } from "svelte-routing";
  import Select from "svelte-select";
  import API from "../../utils/api";
  import State from "../../utils/functions/State";
  import { STATE_LIST } from "../../constants";
  export let data;
  console.log(data.kyc);
  let stateOption;
  let dialogValidate;
  let dialogBlacklist;
  let dialogDelete;
  let dialogKyc;
  let loading = false;
  let kyc_loading = false;
  let stateloading = false;
  let snack;
  let selected_kyc_state;
  let snack_msg = "";
  let snack_color = "primary";
  let statesList = [
    "En cours de souscription",
    "En cours de signature",
    "En cours de remboursement",
    "Terminé",
  ];
  const showSnack = (msg, color = "primary") => {
    snack_msg = msg;
    snack_color = color;
    snack.forceOpen();
  };

  const onUpdate = async (name, val) => {
    try {
      await API.put("admin/subscriber", { id: data.id, [name]: val });
      data[name] = val;
      showSnack("Les données ont été mises à jour");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    }
  };
  const onValidate = async () => {
    dialogValidate.close();
    if (loading) return;
    loading = true;
    try {
      await API.post("admin/accountValidation", { id: data.id });
      data.validated = new Date();
      showSnack("Le compte a été validé");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      loading = false;
    }
  };
  const onAccountUnblacklisting = async () => {
    if (loading) return;
    loading = true;
    snack.close();
    try {
      await API.post("admin/accountUnblacklisting", { id: data.id });
      data.blacklisted = null;
      showSnack("Le compte a été débloqué");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      loading = false;
    }
  };
  const onBlacklist = async () => {
    dialogBlacklist.close();
    if (loading) return;
    loading = true;
    try {
      await API.post("admin/accountBlacklisting", { id: data.id });
      data.blacklisted = new Date();
      showSnack("Le compte a été blacklisté");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      loading = false;
    }
  };
  const onDelete = async () => {
    dialogDelete.close();
    if (loading) return;
    loading = true;
    try {
      await API.post("admin/accountDeletion", { id: data.id });
      data.deleted = new Date();
      showSnack("Le compte a été supprimé");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      loading = false;
    }
  };
  async function devOnKycUpdate() {
    try {
      console.log(selected_kyc_state);
      if (selected_kyc_state !== "del") {
        await API.put("admin/kyc", { id: data.id, state: selected_kyc_state });
      } else {
        await API.del("admin/kyc", { id: data.id });
      }
      // data.kyc.state = selected_kyc_state;
      alert("ok");
    } catch (e) {
      console.error(e);
    }
  }
  const onSendRequestKyc = async () => {
    dialogKyc.close();
    if (kyc_loading) return;
    kyc_loading = true;
    try {
      const response = await API.post("admin/kyc", { id: data.id });
      data.kyc.state = response.state;
      data.kyc.pdfUrl = response.pdfUrl;
      showSnack("L'envoie de la demande kyc a été effectuée");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    } finally {
      kyc_loading = false;
    }
  };
  let firstName = data.firstName || "";
  let lastName = data.lastName || "";
  let phone = data.phone || "";
  let email = data.email || "";
  let company = data.company || "";

  // let kyc = data.kyc || {};

  $: date = new Date(data[State.advisor(data).value]).toLocaleDateString(
    "fr-FR"
  );
  // $: list = STATE_LIST.filter((el) => State.advisor(data).value !== el.value);
  $: is_in_confirmed_state = State.advisor(data).value === "confirmed";
  $: is_in_validated_state = State.advisor(data).value === "validated";
  $: is_in_blacklist_state = State.advisor(data).value === "blacklisted";
  $: kyc_state =
    data.kyc.state == null
      ? "Aucun KYC"
      : data.kyc.state == "inProgress"
      ? "Requête en cours"
      : data.kyc.state == "missingDocuments"
      ? "Documents manquants"
      : data.kyc.state == "allDocumentsReceived"
      ? "Documents reçus"
      : data.kyc.state == "cannotBeValidated"
      ? "La requête ne peut pas être validé"
      : data.kyc.state == "validated"
      ? "Validé"
      : "Une erreur est survenue";
  let invited = data.invitedBy && data.invitedBy.length;
</script>

<AppSnackbar bind:value={snack} color={snack_color}>
  {snack_msg}
</AppSnackbar>
<Dialog bind:this={dialogValidate}>
  <Content style="width:400px;">
    <AppText color="blue" weight="900" size="large">Valider le compte?</AppText>
    <Spacing y="8" />
    <AppText>Êtes-vous sûr de vouloir valider le compte ?</AppText>
    <Spacing y="8" />
    <AppButton block on:click={onValidate}>Valider le compte</AppButton>
  </Content>
</Dialog>
<Dialog bind:this={dialogBlacklist}>
  <Content style="width:400px;">
    <AppText color="danger" weight="900" size="large">
      Bloquer le compte ?
    </AppText>
    <Spacing y="8" />
    <AppText>Êtes-vous sûr de vouloir bloquer le compte ?</AppText>
    <Spacing y="8" />
    <AppButton block color="danger" on:click={onBlacklist}
      >Bloquer le compte
    </AppButton>
  </Content>
</Dialog>
<Dialog bind:this={dialogDelete}>
  <Content style="width:400px;">
    <AppText color="danger" weight="900" size="large">
      Supprimer le compte ?
    </AppText>
    <Spacing y="8" />
    <AppText>Êtes-vous sûr de vouloir supprimer le compte ?</AppText>
    <Spacing y="8" />
    <AppButton block color="danger" on:click={onDelete}
      >Supprimer le compte
    </AppButton>
  </Content>
</Dialog>
<Dialog bind:this={dialogKyc}>
  <Content style="width:400px;">
    <AppText color="blue" weight="900" size="large">
      Valider l'envoie de la requête KYC au souscripteur?
    </AppText>
    <Spacing y="8" />
    <AppText>
      Êtes-vous sûr de vouloir envoyer la requête KYC au souscripteur {data.firstName +
        " " +
        data.lastName} ?
    </AppText>
    <Spacing y="8" />
    <AppButton block color="primary" on:click={onSendRequestKyc}>
      Envoyer la requête KYC
    </AppButton>
  </Content>
</Dialog>
<div>
  <AppText size="large" color="blue" weight="bold">Etat du compte</AppText>

  <Spacing y="8">
    <section>
      <AppBadge color={State.advisor(data).color} {date}>
        {State.advisor(data).text}
      </AppBadge>
      {#if is_in_confirmed_state}
        <AppButton {loading} block on:click={() => dialogValidate.open()}>
          Valider le compte
        </AppButton>
      {:else if is_in_validated_state}
        <AppButton
          {loading}
          color="danger"
          block
          on:click={() => dialogBlacklist.open()}
        >
          Bloquer le compte
        </AppButton>
      {:else if is_in_blacklist_state}
        <AppButton
          {loading}
          color="warning"
          block
          on:click={onAccountUnblacklisting}
        >
          Débloquer le compte
        </AppButton>
      {/if}
    </section>
  </Spacing>
  <Spacing divider divider_props={{ top: 8, bottom: 12 }} />
  <section>
    <span style="display:flex;align-items:flex-end;">
      <AppText color="blue" weight="bold">Etat Kyc:</AppText>
      <Spacing x="8" />
      <AppText weight="bold">{kyc_state}</AppText>
    </span>
  </section>
  {#if !data.kyc.state || data.kyc.state === "cannotBeValidated"}
    <Spacing y="6" />
    <AppButton
      bind:loading={kyc_loading}
      color="primary"
      block
      on:click={() => dialogKyc.open()}
    >
      Envoyer la requête KYC
    </AppButton>
  {:else}
    <Spacing y="6" />
    <AppButton
      color="warning"
      block
      on:click={() => (window.location.href = data.kyc.pdfUrl)}
    >
      Télécharger le document KYC
    </AppButton>
  {/if}
  <select bind:value={selected_kyc_state}>
    <option value="inProgress" selected>inProgress</option>
    <option value="missingDocuments">missingDocuments</option>
    <option value="allDocumentsReceived">allDocumentsReceived</option>
    <option value="cannotBeValidated">cannotBeValidated</option>
    <option value="validated">validated</option>
    <option value="del">DELETED</option>
  </select>

  <button style="margin-top:8px;dislay:block;" on:click={devOnKycUpdate}>
    [dev only]:valider la requête kyc
  </button>
  {selected_kyc_state}
</div>

{#if invited}
  <Spacing y="12" />
  <div>
    <AppText size="large" color="blue" weight="bold"
      >Rattaché aux gestionnaires</AppText
    >
    <Spacing y="8">
      <AppTable empty={!invited} header={["Prénom", "Nom", "Email"]}>
        {#each data.invitedBy as advisor}
          <Row>
            <Cell>
              <AppText>
                {advisor.firstName}</AppText
              >
            </Cell>
            <Cell>
              <AppText>
                {advisor.lastName}
              </AppText>
            </Cell>
            <Cell>
              <AppText>
                <AppButton
                  on:click={navigate(`/managers/${advisor.id}`, {
                    replace: true,
                  })}
                  size="small"
                  color="light"
                >
                  {advisor.email}
                </AppButton>
              </AppText>
            </Cell>
          </Row>
        {/each}
      </AppTable>
    </Spacing>
  </div>
{/if}

<Spacing y="8" />
<div>
  <AppText size="medium" color="blue" weight="900"
    >Informations Participant</AppText
  >
  <Spacing y="8" />
  <section>
    <AppInput
      label="Nom"
      bind:value={lastName}
      on:blur={() => onUpdate("lastName", lastName)}
      crop
    />
    <AppInput
      label="Prénom"
      bind:value={firstName}
      on:blur={() => onUpdate("firstName", firstName)}
      crop
    />
  </section>
  <Spacing y="8" />
  <section>
    <AppInput
      label="Email"
      bind:value={email}
      on:blur={() => onUpdate("email", email)}
      crop
    />
    <AppInput
      label="Téléphone"
      bind:value={phone}
      on:blur={() => onUpdate("phone", phone)}
      crop
    />
  </section>
  <Spacing y="8" />
  <section>
    <AppInput
      label="Société"
      bind:value={company}
      on:blur={() => onUpdate("company", company)}
      crop
    />
  </section>
</div>

<AppButton {loading} color="danger" block on:click={() => dialogDelete.open()}>
  Supprimer le compte
</AppButton>

<style>
  section {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);
    @include respond-to("md") {
      grid-gap: 0;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
    }
    /* &.middle {
      align-items: center;
    } */
  }
  div {
    padding: 16px;
    box-shadow: $primary-shadow;
    margin-bottom: 16px;
    border-radius: 4px;
  }
  /* p {
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    color: $blue;
  } */
</style>
