<script>
  import Textfield from "@smui/textfield";
  // import {Label, Icon} from '@smui/common'
  import Text from "../Text/AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  import { formatNumber, parseNumber } from "../../stores/i18n";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  /**@props {string} label - label's input*/
  export let id = undefined;
  export let label = undefined;
  export let autofocus = false;
  export let suffix;
  export let value;
  export let minimumFractionDigits = 0;
  export let maximumFractionDigits = 2;
  export let required = false;
  export let type = "text";
  export let outlined = true;
  export const uppercase = false;
  export let light = false;
  export let disabled = false;
  export let rules = undefined;
  export let show_rules = false;
  export let message_error = "Une erreur est survenue";
  export let crop = false;
  export let min = 0;
  export let max = undefined;
  export let step = undefined;
  export let onChange = (e) => {};
  export let onClick = (e) => {};
  export let onInput = (e) => {};
  const activate_rules = (e) => {
    onInput(e);
    if (rules && show_rules) return;
    show_rules = true;
  };
  let variant = outlined ? "outlined" : "";
  $: asterix = required ? "*" : "";
  $: style = light ? "opacity:0.8;" : "";
  $: show_rules_component = show_rules && rules !== undefined;
  $: every_rules_is_good =
    rules !== undefined
      ? rules.every((element) => {
          if (element instanceof RegExp) {
            return element.test(value);
          } else if (typeof element === "boolean") {
            return element;
          } else {
            throw new Error("Errox rules: should be a Boolean or a RegExp");
          }
        })
      : undefined;

  const parseValue = (value) => {
    if (type == "number") {
      return typeof value == "number" ? value : $parseNumber(value);
    }
    return value;
  };
  const checkValueBounds = (value) => {
    if (type === "number") {
      if (!value) {
        return min;
      }
      if (min && value < min) {
        return min;
      }
      if (max && value > max) {
        return max;
      }
    }
    return value;
  };
  const formatValue = (value) => {
    return type == "number" ? $formatNumber(value, minimumFractionDigits, maximumFractionDigits) : value;
  };
  let displayValue = formatValue(checkValueBounds(parseValue(value)));
  $: {
    value = checkValueBounds(parseValue(displayValue));
    displayValue = formatValue(checkValueBounds(parseValue(value)));
  }
</script>

<div {id}>
  {#if label}
    <section {style}>
      <Text {uppercase} color="primary" weight="700">
        <main>
          {label}
          {#if asterix}<span>{asterix}</span>{/if}:
        </main>
      </Text>
    </section>
  {/if}
  {#if outlined}
    <Spacing y="6" />
  {/if}
  <Textfield
    dense
    on:blur={(e) => dispatch("blur", e)}
    on:input={activate_rules}
    {variant}
    {disabled}
    on:change={onChange}
    on:click={onClick}
    bind:value={displayValue}
    type={type == "password"? "password": "text"}
    input$autofocus={autofocus}
    >
    {#if suffix}
      <aside>{suffix}</aside>
    {/if}
  </Textfield>
  {#if !crop}
    <span class:error={show_rules_component && !every_rules_is_good}>
      <b>{message_error}</b>
    </span>
  {/if}
</div>

<style lang="scss">
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    aside {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
      padding-right: 8px;
      margin-top: 3px;
      font-family: "Lato";
      background: white;
      z-index: 2;
      height: 50%;
      display: flex;
      align-items: center;
    }
    main {
      display: flex;
    }
    span {
      font-size: 11px;
      display: flex;
      height: 28px;
      overflow: hidden;
      font-family: "Lato";
      color: $danger;
      position: relative;
      left: 0;
      * {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all 300ms ease;
      }
      &.error {
        * {
          opacity: 1;
          transition: all 200ms ease;
          font-weight: normal;
          top: 30%;
          transform: translateY(-30%);
        }
      }
    }
  }
</style>
