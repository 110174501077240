<script>
  import { onMount } from "svelte";
  //import AppCard from "./AppCard.svelte";
  import AppBadge from "../Badge/AppBadge.svelte";
  import AppImg from "../Img/AppImg.svelte";
  import AppText from "../Text/AppText.svelte";
  import { createEventDispatcher } from "svelte";
  import { INSTRUMENTS_LISTS, TYPOLOGIES_LISTS } from "../../constants";
  import { i18n, formatNumber } from "../../stores/i18n";
  import API from "../../utils/api";

  export let id;
  export let rate = 0;
  export let amount = 0;
  export let subscriptionAmount;
  export let duration = 0;
  export const hideCursor = false;
  export let companyName = "";
  export let instrument;
  export let typology;
  export let state;

  const dispatch = createEventDispatcher();
  const onClick = () => dispatch("click");

  const stateProps = {
    notSubmitted: {
      text: ["Non soumis"],
      color: "disabled",
    },
    toBeLaunched: {
      text: ["A lancer"],
      color: "primary",
    },
    subscription: {
      text: ["En cours de souscription"],
      color: "primary",
    },
    signature: {
      text: ["En cours de signature"],
      color: "progress",
    },
    running: {
      text: ["En cours de remboursement"],
      color: "progress",
    },
    launched: {
      text: ["Lancé"],
      color: "progress",
    },
    launched: {
      text: ["Lancé"],
      color: "progress",
    },
    mature: {
      text: ["Clôturé"],
      color: "disabled",
    },
    suspended: {
      text: ["Suspendu"],
      color: "disabled",
    },
  };
  const getStateProps = (state) => {
    let res = stateProps[state];
    return res ? res : { text: ["[" + state + "]"], color: "disabled" };
  };

  // const style = {
  //   padding: "10px 9px",
  //   display: "inline-block",
  // };

  const defaultLogo = "/assets/default-logo.png";
  let src = defaultLogo;
  onMount(() => {
    // src = API.baseURL + "logo?id=" + id;
    // TODO: call api, get tmp url, set src = tmp url
  });
</script>

<main on:click={onClick}>
  <header>
    <AppBadge color={getStateProps(state).color}
      >{getStateProps(state).text}</AppBadge
    >
  </header>
  <section>
    <AppImg
      size="large"
      {src}
      onerror="this.onerror=null; this.src='{defaultLogo}'"
    />
    <aside>
      <article>
        <div class="companyheader">
          <AppText weight="700">{companyName}</AppText>
          {#if typology}
            <AppText size="small" weight="700" color="gray"
              >{(
                TYPOLOGIES_LISTS.find((el) => el.value === typology) || {
                  label: [""],
                }
              ).label}</AppText
            >
          {/if}
        </div>
        {#if amount}
          <span>
            <AppBadge color="gradient">
              <div class="badge-0">
                <AppText color="white" weight="700">Montant</AppText>
                <AppText color="white" weight="700">
                  <p>
                    {$formatNumber(amount / 1e6)} M€
                  </p>
                </AppText>
              </div>
            </AppBadge>
          </span>
        {/if}
      </article>
    </aside>
  </section>
  {#if rate}
    <main class="badge">
      <div class="badge-1">
        <AppText size="small" weight="700">Taux</AppText>
        <AppText weight="700" color="danger">{rate}%</AppText>
      </div>
    </main>
    <b />
  {/if}
  {#if duration}
    <main class="badge">
      <div class="badge-2">
        <AppText size="small" weight="700">Durée</AppText>
        <AppText weight="700" color="progress">{duration} mois</AppText>
      </div>
    </main>
    <b />
  {/if}
  {#if subscriptionAmount}
    <main class="badge">
      <div class="badge-3">
        <AppText size="small" weight="700" color="blue">Souscription</AppText>

        <AppText weight="900" color="blue">
          <p>
            {subscriptionAmount / 1e6} M€
          </p></AppText
        >
      </div>
    </main>
  {/if}
</main>

<style lang="scss">
  main {
    cursor: pointer;
    box-shadow: $primary-shadow;
    border-radius: 4px;
    padding: 16px 16px 0 16px;
    margin-bottom: 12px;
    &.badge {
      padding: 10px 9px;
      display: inline-block;
    }
  }
  header {
    margin-bottom: 12px;
  }
  section {
    display: flex;
    margin-bottom: 12px;
    aside {
      margin-left: 12px;
      article {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        div.companyheader {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        span {
          display: block;
          div {
            display: flex;
            justify-content: space-between;
            p {
              display: flex;
              align-items: center;
              /* i {
                font-size: 14px;
                line-height: 1.5;
              } */
            }
          }
        }
      }
    }
  }
  b {
    margin-left: 4px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      /* i {
        font-size: 14px;
      } */
    }
    &.badge {
      &-0 {
        width: 124px;
      }
      &-1 {
        width: 64px;
      }
      &-2 {
        width: 110px;
      }
      &-3 {
        width: 114px;
        /* span {
          margin-top: 1.5px;
        } */
      }
    }
  }
</style>
