import { writable } from "svelte/store"
// import { ADVISOR_PATH, CLUBDEAL_PATH } from "../constants"
// import { i18n } from "./i18n"

const fake_profile = {
    afiteMember: null,
    type: null, //subscriber or advisor
    billingAddressCity: null,
    billingAddressCountry: null,
    billingAddressNumberAndStreet: null,
    billingAddressPostalCode: null,
    company: null,
    confirmed: null,
    created: null,
    email: null,
    favorites: null,
    firstName: null,
    language: null,
    lastName: null,
    lastSignin: null,
    lastSigning: null,
    // ndaNotSigned: null,
    phone: null,
    turnover: null,
    validated: null,
}

function createProfile() {
    const { subscribe, set } = writable(fake_profile);
    return {
        subscribe,
        set: (el) => set(el)
    }
}

export const profile = createProfile()