<script>
  import { fade } from "svelte/transition";
  export let loading = true;
</script>

<main in:fade>
  {#if loading}
    <figure transition:fade|local>
      <img src="/assets/tail-spin.svg" />
    </figure>
  {:else}
    <div transition:fade|local={{delay:500}}>
      <slot />
    </div>
  {/if}
</main>

<style>
  main {
    /* max-width: 100%;
    overflow: hidden; */
    figure {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include respond-to('md'){
        left: 224px;
      }
      img {
        width: 64px;
      }
    }
    div {
      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
    }
  }
</style>
