<script>
  import Radio from "@smui/radio";
  import FormField from "@smui/form-field";
  import Dialog, { Content } from "@smui/dialog";
  import AppSnackbar from "../../components/Snackbar/AppSnackbar.svelte";
  import Select from "svelte-select";
  import AppText from "../../components/Text/AppText.svelte";
  import TextField from "../../components/Text/TextField.svelte";
  import AppBadge from "../../components/Badge/AppBadge.svelte";
  import Spacing from "../../components/Spacing/Spacing.svelte";
  import AppButton from "../../components/Button/AppButton.svelte";
  import AppInput from "../../components/Form/Input.svelte";
  import API from "../../utils/api";
  import { navigate } from "svelte-routing";

  export let data;
  import {
    REGION_LIST,
    COUNTRY_LIST,
    INSTRUMENTS_LISTS,
    PERIODICITIES_LIST,
    TYPOLOGIES_LISTS,
  } from "../../constants";
  import State from "../../utils/functions/State";

  let legalForm = ["SA", "SAS", "SCA", "SARL"];
  let dialog;
  let dialog_delete;
  let dialog_state;
  let button_loading = false;
  let snack;
  let snack_msg;
  let snack_color;
  let selectedRegion = "";
  let selectCountry = "";
  let list = [
    {
      value: "notSubmitted",
      label: "Non soumis",
    },
    {
      value: "subscription",
      label: "En cours de souscription",
    },
    {
      value: "signature",
      label: "En cours de signature",
    },
    {
      value: "running",
      label: "En cours de remboursement",
    },
    {
      value: "mature",
      label: "Terminé",
    },
    {
      value: "suspended",
      label: "Suspendu",
    },
  ]; // .sort((l, r) => l.label.localeCompare(r.label));

  const onSelectCountry = (e) => {
    data.company.headquartersRegion = null;
    data.company.headquartersCountry = e.detail.value;
    const { headquartersCountry, headquartersRegion } = data.company;
    onChange(null, { headquartersCountry, headquartersRegion });
  };
  const onSelectRegion = (e) => {
    data.company.headquartersRegion = e.detail.value;
    data.company.headquartersCountry = null;
    const { headquartersCountry, headquartersRegion } = data.company;
    onChange(null, { headquartersCountry, headquartersRegion });
  };
  const showSnack = (msg, color = "primary") => {
    snack_msg = msg;
    snack_color = color;
    snack.forceOpen();
  };
  const onValidate = async () => {
    if (button_loading) return;
    button_loading = true;
    dialog.close();
    try {
      await API.post("admin/clubdealValidation", {
        id: data.id,
      });
      data.state = "subscription";
    } catch (e) {
      console.error(e);
    } finally {
      button_loading = false;
    }
  };
  const onDelete = async () => {
    if (button_loading) return;
    button_loading = true;
    dialog_delete.close();
    try {
      await API.del("admin/clubdeal", {
        id: data.id,
      });
      data.state = "deleted";
      navigate(`/dossiers`, { replace: true, })
    } catch (e) {
      console.error(e);
    } finally {
      button_loading = false;
    }
  };
  const onUpdateState = async (state) => {
    if (button_loading) return;
    button_loading = true;
    console.log(state);
    dialog_state.close();
    try {
      await API.put("admin/clubdeal", {
        id: data.id,
        state,
      });
      data.state = state;
    } catch (e) {
      console.error(e);
    } finally {
      button_loading = false;
    }
  };
  const onChange = async () => {
    try {
      console.log("data:", data);
      await API.put("admin/clubdeal", data);
      showSnack("Les données ont été mises à jour");
    } catch (e) {
      console.error(e);
      showSnack("Une erreur est survenue, veuillez recommencer", "danger");
    }
  };
  $: region = REGION_LIST.find(
    (el) => el.value === data.company.headquartersRegion
  );
  $: country = COUNTRY_LIST.find(
    (el) => el.value === data.company.headquartersCountry
  );
  $: state = list.find((el) => el.value === data.state);
  $: snack_pros = {
    color: snack_color,
    labelText: snack_msg,
  };
</script>

<main>
  <AppSnackbar bind:value={snack} {...snack_pros} />
  <Dialog bind:this={dialog}>
    <Content style="width:400px;">
      <AppText color="blue" weight="900" size="large"
        >Valider la dataroom?</AppText
      >
      <Spacing y="8" />
      <AppText>Êtes-vous sûr de vouloir valider la dataroom ?</AppText>
      <Spacing y="8" />
      <Spacing y="32" />
      <AppButton block on:click={onValidate}>Valider la dataroom</AppButton>
    </Content>
  </Dialog>
  <Dialog bind:this={dialog_delete}>
    <Content style="width:400px;">
      <AppText color="blue" weight="900" size="large"
        >Supprimer la dataroom?</AppText
      >
      <Spacing y="8" />
      <AppText>Êtes-vous sûr de vouloir supprimer la dataroom ?</AppText>
      <Spacing y="8" />
      <Spacing y="32" />
      <AppButton block on:click={onDelete}>Supprimer la dataroom</AppButton>
    </Content>
  </Dialog>
  <Dialog bind:this={dialog_state}>
    <Content style="width:400px;">
      <AppText color="blue" weight="900" size="large">
        Modifier l'état de la dataroom
      </AppText>
      <Spacing y="8" />
      <main
        style="z-index:1;position:absolute; display: block; width: calc(100% - 44px);"
        class="themed"
      >
        <Select
          items={list}
          on:select={(e) =>
            (state = list.find((el) => el.value === e.detail.value))}
          isMulti={false}
          noOptionsMessage="Plus d'options"
          selectedValue={state}
        />
      </main>
      <Spacing y="32" />
      <AppButton disabled={!state} on:click={() => onUpdateState(state.value)}>
        Valider
      </AppButton>
    </Content>
  </Dialog>
  <AppText size="large" color="blue" weight="bold">Etat de la dataroom</AppText>
  <Spacing y="8">
    <section>
      <AppBadge color={State.clubdeal(data.state).color} date={" "}
        >{State.clubdeal(data.state).text}</AppBadge
      >
      <!-- {#if data.state === "toBeLaunched" || data.state == "notSubmitted"} -->
      {#if ["toBeLaunched", "notSubmitted"].includes(data.state)}
        <AppButton
          loading={button_loading}
          on:click={() => dialog.open()}
          block
        >
          Valider la dataroom
        </AppButton>
      {:else}
        <AppButton
          on:click={() => dialog_state.open()}
          loading={button_loading}
          block
          color="warning">Modifier l'état de la dataroom</AppButton
        >
      {/if}
    </section>
  </Spacing>
  <Spacing y="8">
    <section>
      <AppButton
          on:click={() => dialog_delete.open()}
          loading={button_loading}
          block
          color="warning">Supprimer la dataroom</AppButton
        >
    </section>
  </Spacing>
  <Spacing y="8" />
  <div>
    <TextField color="blue">Informations: Société</TextField>
    <Spacing y="8" />
    <AppInput
      label="Nom de la société"
      bind:value={data.company.name}
      crop
      onChange={onChange}
    />
    <Spacing y="8" />
    <AppText color="secondary" weight="700">Forme juridique:</AppText>
    <Spacing y="4" />
    {#each legalForm as option}
      <FormField>
        <Radio
          bind:group={data.company.legalForm}
          value={option}
          touch
          onChange={onChange}
        />
        <span slot="label">{option}</span>
      </FormField>
    {/each}
    <Spacing y="8" />
    <AppText color="secondary" weight="700">Lieu du siège social:</AppText>
    <Spacing y="8" />
    <section>
      <main class="themed">
        <Select
          items={REGION_LIST.sort((l, r) => l.label.localeCompare(r.label))}
          on:select={onSelectRegion}
          isMulti={false}
          placeholder="Choisir une région"
          noOptionsMessage="Plus d'options"
          selectedValue={region}
        />
      </main>
      <main class="themed">
        <Select
          items={COUNTRY_LIST.sort((l, r) => l.label.localeCompare(r.label))}
          on:select={onSelectCountry}
          isMulti={false}
          placeholder="Choisir un pays"
          noOptionsMessage="Plus d'options"
          selectedValue={country}
        />
      </main>
    </section>
  </div>

  <div>
    <TextField color="blue">
      Informations: caratéristiques de l'opération
    </TextField>
    <Spacing y="8" />
    <section>
      <AppInput
        label="Montant"
        type="number"
        suffix="€"
        bind:value={data.amount}
        crop
        onChange={onChange}
      />
      <AppInput
        label="Taux"
        suffix="%"
        type="number"
        bind:value={data.rate}
        minimumFractionDigits={1}
        crop
        onChange={onChange}
      />
    </section>
    <Spacing y="8" />
    <AppInput
      label="Durée"
      type="number"
      suffix="mois"
      bind:value={data.maturity}
      crop
      onChange={onChange}
    />
    <Spacing y="8" />
    <AppText color="secondary" weight="700">Typologie de l'opération:</AppText>
    <Spacing y="4" />
    {#each TYPOLOGIES_LISTS as typology}
      <FormField>
        <Radio
          bind:group={data.typology}
          value={typology.value}
          touch
          on:change={() => onChange("typology")}
        />
        <span slot="label">{typology.label}</span>
      </FormField>
    {/each}
    <!-- <AppText color="secondary" weight="700">Typologie de financement:</AppText>
    <Spacing y="4" />
    {#each INSTRUMENTS_LISTS as instrument}
      <FormField>
        <Radio
          bind:group={data.instrument}
          value={instrument.value}
          touch
          on:change={() => onChange("instrument")}
        />
        <span slot="label">{instrument.label}</span>
      </FormField>
    {/each}
    <Spacing y="4" />
    <AppText color="secondary" weight="700">Fréquence des coupons:</AppText>
    <Spacing y="4" />
    {#each PERIODICITIES_LIST as periodicity}
      <FormField>
        <Radio
          bind:group={data.periodicity}
          value={periodicity.value}
          touch
          on:change={() => onChange("periodicity")}
        />
        <span slot="label">{periodicity.label}</span>
      </FormField>
    {/each} -->
  </div>
</main>

<style>
  section {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(1, 1fr);
    @include respond-to("md"){
      grid-gap: 0;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
    }
  }
  div {
    padding: 16px 0;
    width: 100%;

    /* box-shadow: $primary-shadow; */
    margin-bottom: 16px;
  }
</style>
