<script>
  import AppText from "./AppText.svelte";
  import Spacing from "../Spacing/Spacing.svelte";
  export let color = "primary";
</script>

<section>
  <AppText weight="900" size="medium" {color}>
    <slot />
  </AppText>
  <main>
    <Spacing divider  />
  </main>
</section>

<style>
  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    main {
      flex: 1 1 auto;
      margin-left: 8px;
      margin-top: 2px;
    }
  }
</style>
